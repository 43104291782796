import React, { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import imgLogo from '../src/img/Login/logo.svg';
import Quienes_01 from "../src/img/QuienesSomos/n_01.jpg";
import Quienes_02 from "../src/img/QuienesSomos/n_02.jpg";
import Quienes_03 from "../src/img/QuienesSomos/n_03.jpg";

const QuienesSomos = () => {
    
    useEffect(() => {
        // Agrega una clase específica al body
        document.body.classList.add("no-bold-paragraphs");
    
        // Limpia la clase al salir de la página
        return () => {
          document.body.classList.remove("no-bold-paragraphs");
        };
      }, []);


    return (
        <div className="split-container" style={{ overflow: "auto" }}>
          {/* Sección izquierda */}
          <div className="split-left d-none d-sm-block">
            <Container style={{ padding: "0" }}>
              <Row>
                <Col sm={12}>
                  <img
                    src={Quienes_01}
                    style={{
                      width: "100%",
                      
                      objectFit: "cover",
                    }}
                    alt="Escalada en Nieve"
                  />
                </Col>
              </Row>
            </Container>
          </div>
      
          {/* Sección derecha */}
          <div className="split-right">
            <Container style={{ paddingTop: "calc(1em + 1vw)" }}>
              {/* Logo */}
              <Row>
                <Col sm={12}>
                  <center>
                    <img
                      src={imgLogo}
                      alt="Logo"
                      style={{ maxWidth: "200px", marginBottom: "20px"}}
                    />
                  </center>
                </Col>
              </Row>
      
              {/* Introducción */}
              <Row>
                <Col sm={12}>
                  <p style={{ fontSize: "12pt", margin: "40px", textAlign: "justify" }}>
                    Nuestro propósito es brindarte un espacio de conexión y encuentro
                    con personas de interés similares, donde podrás participar en todo
                    tipo de eventos y actividades, nutrir tu talento literario,
                    aprender un nuevo idioma o practicar tu deporte favorito. Las
                    posibilidades son infinitas. Aquí fomentamos la diversidad y la
                    conexión entre todo tipo de personas, y todo esto en un espacio
                    seguro y de bienestar.
                  </p>
                </Col>
              </Row>
      
              {/* Misión */}
              <Row className="mb-4">
                <Col sm={6}>
                  <h4><b style={{margin: "40px"}}>Misión</b></h4>
                  <p style={{ fontSize: "10pt",margin: "40px", textAlign: "justify" }}>
                  Conéctate Perú, es creado para convertirse en un referente nacional para unir personas con intereses comunes y nuestra plataforma adopta esta idea innovadora con un enfoque inclusivo y dinámico. Nuestra misión principal es ser el puente que conecte personas, proyectos y comunidades. Creemos firmemente que cada persona tiene el poder de enriquecer su vida social y profesional al interactuar con otras personas que comparten los mismos objetivos y aspiraciones.

                  </p>
                </Col>
                <Col sm={6}>
                  <img
                    src={Quienes_02}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                    alt="Misión"
                  />
                  <p style={{ fontSize: "10pt",paddingTop: "2px", textAlign: "justify" }}>En nuestra plataforma ofrecemos herramientas accesibles y efectivas, que facilitan la creación de grupos y la organización de eventos de manera ágil y segura.
                  En esta plataforma fomentamos conexiones reales y significativas; diseñado para la diversidad e inclusión.</p>
                </Col>
              </Row>
      
              {/* Visión */}
              <Row className="mb-4">
                <Col sm={6}>
                  <img
                    src={Quienes_03}
                    className="imgQSomos3"
                    alt="Visión"
                  />
                </Col>
                <Col sm={6}>
                  <h4><b className='titleQsomos3'>Visión</b></h4>
                  <p className='textQSomos3'>
                    Nuestra visión es ser la plataforma líder en conectar personas y
                    comunidades de todo el país. Visualizamos ser un referente para la
                    creación de redes culturales, sociales y profesionales, impulsando
                    el crecimiento personal, la colaboración y la innovación con un
                    enfoque inclusivo y tecnológico.
                  </p>
                </Col>
              </Row>
              {/* Visión mobile*/}
              <Row className="mb-4">
                <Col sm={6}>
                <h4><b className='titleQSomosMobile'>Visión</b></h4>
                  <p className='textQSomosMobile'>
                    Nuestra visión es ser la plataforma líder en conectar personas y
                    comunidades de todo el país. Visualizamos ser un referente para la
                    creación de redes culturales, sociales y profesionales, impulsando
                    el crecimiento personal, la colaboración y la innovación con un
                    enfoque inclusivo y tecnológico.
                  </p>
                  
                </Col>
                <Col sm={6}>
                <img
                    src={Quienes_03}
                    className="imgQSomosMobile"
                    alt="Visión"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
          
};

export default QuienesSomos;
